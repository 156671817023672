import './assets/css/tailwind.output.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Windmill } from '@windmill/react-ui';

import App from './App';
import ThemedSuspense from './components/ThemedSuspense';
// providers
import { ScrollProvider } from './context/ScrollContext';
import { SidebarProvider } from './context/SidebarContext';
import { UserProvider } from './context/UserContext';
import { UserTagsProvider } from './context/UserTagsContext';
import * as serviceWorker from './serviceWorker';

export const createStoreProvider =
  (providers) =>
  ({ children }) =>
    providers
      .reverse()
      .reduce((tree, Provider) => <Provider>{tree}</Provider>, children);

const ProviderWrapper = createStoreProvider([
  ScrollProvider,
  UserProvider,
  SidebarProvider,
  UserTagsProvider,
]);

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(
  <ProviderWrapper>
    <Suspense fallback={<ThemedSuspense />}>
      <Windmill usePreferences>
        <App />
      </Windmill>
    </Suspense>
  </ProviderWrapper>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
