import React, { createContext, useEffect, useMemo, useState } from 'react';

import usersApi from '../api/users.api';

export const UserTagsContext = createContext();

export const UserTagsProvider = ({ children }) => {
  const [userTags, setUserTags] = useState([]);

  const syncUserTags = () => usersApi.listAllTags().then(setUserTags);

  useEffect(() => {
    syncUserTags();
  }, []);

  const value = useMemo(
    () => ({
      userTags,
      setUserTags,
      syncUserTags,
    }),
    [userTags]
  );

  return (
    <UserTagsContext.Provider value={value}>
      {children}
    </UserTagsContext.Provider>
  );
};

export const useUserTags = () => {
  const context = React.useContext(UserTagsContext);

  if (context === undefined) {
    throw new Error('useUserTags must be used within a UserTagsProvider');
  }

  return context;
};
