import React, { lazy, useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./containers/PrivateRoute";
import { hasJWT } from "./utils/jwt";
import loginApi from "./api/login.api";
import { UserContext } from "./context/UserContext";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const Splash = lazy(() => import("./pages/Splash"));

function App() {
  const { setUser } = useContext(UserContext);
  const [hasVerifiedUser, setHasVerifiedUser] = useState(false);

  useEffect(() => {
    (async function () {
      if (hasJWT()) {
        const user = await loginApi.me();

        if (user) {
          setUser(user);
        }
      }

      setHasVerifiedUser(true);
    })();
  }, [setUser]);

  if (!hasVerifiedUser) {
    return <Splash />;
  }

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />

          {/* Place new routes over this */}
          <PrivateRoute path="/app" component={Layout} />

          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
