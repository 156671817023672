import axios from 'axios';

import jwt from '../utils/jwt';

class UsersAPI {
  static usersListCache = [];
  static usersTagsCache = [];

  get(userId) {
    const config = {
      ...jwt(),
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/users/${userId}`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserActivity(
    userId,
    params = {
      page: 1,
      limit: 25,
    }
  ) {
    const config = {
      ...jwt(),
      params,
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/users/${userId}/activity`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  list() {
    const config = {
      ...jwt(),
    };

    const { usersListCache } = UsersAPI;

    if (usersListCache && usersListCache.length) {
      return usersListCache;
    }

    return axios
      .get(`${process.env.REACT_APP_API_URL}/users`, config)
      .then((response) => {
        UsersAPI.usersListCache = response.data;
        return response.data.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  total(fromDate, toDate) {
    const config = {
      ...jwt(),
    };

    let queryString = '';

    if (fromDate && toDate) {
      queryString = `?fromDate=${fromDate}&toDate=${toDate}`;
    }

    return axios
      .get(`${process.env.REACT_APP_API_URL}/users/total${queryString}`, config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  activity(fromDate, toDate) {
    const config = {
      ...jwt(),
    };

    let queryString = '';

    if (fromDate && toDate) {
      queryString = `?fromDate=${fromDate}&toDate=${toDate}`;
    }

    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/users/activity${queryString}`,
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  removeTag(userId, tagId) {
    const config = {
      ...jwt(),
    };

    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/users/${userId}/tags/${tagId}`,
        config
      )
      .then((response) => {
        return response.data;
      });
  }

  addTag(userId, tag) {
    const config = {
      ...jwt(),
      headers: {
        ...jwt().headers,
        'Content-Type': 'application/json',
      },
    };

    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/users/${userId}/tags`,
        tag,
        config
      )
      .then((response) => response.data.data);
  }

  async listAllTags() {
    // if (UsersAPI.usersTagsCache && UsersAPI.usersTagsCache.length) {
    //   return UsersAPI.usersTagsCache;
    // }

    const config = {
      ...jwt(),
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/users/tags`, config)
      .then((response) => {
        UsersAPI.usersTagsCache = response.data.data;

        return UsersAPI.usersTagsCache;
      });
  }

  remove(userId) {
    const config = {
      ...jwt(),
    };

    return axios
      .delete(`${process.env.REACT_APP_API_URL}/users/${userId}`, config)
      .then((response) => response.data);
  }
}

const usersAPI = new UsersAPI();

export default usersAPI;
