import axios from 'axios';

import jwt from '../utils/jwt';

class LoginAPI {
  login(email, password) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        email,
        password,
      })
      .then((response) => {
        if (response.data) {
          const user = response.data.data;
          const accessToken = user.accessToken;

          this._handleUserRoleVerification(user);

          if (accessToken) {
            localStorage.setItem('jwt', JSON.stringify(accessToken));
          }

          return user;
        }

        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  me() {
    const config = {
      ...jwt(),
    };

    return axios
      .get(`${process.env.REACT_APP_API_URL}/auth/me`, config)
      .then((response) => {
        const user = response.data.data;

        this._handleUserRoleVerification(user);

        return user;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  logout() {
    localStorage.removeItem('jwt');
  }

  _handleUserRoleVerification(user) {
    if (!user.isAdmin) {
      localStorage.removeItem('jwt');
      return window.location.replace('https://gudnest.com');
    }
  }
}

const loginAPI = new LoginAPI();

export default loginAPI;
