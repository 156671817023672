import React, { createContext, useContext, useMemo, useState } from 'react';

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const [scrollElement, setScrollElement] = useState(null);

  const value = useMemo(
    () => ({
      scrollElement,
      setScrollElement,
    }),
    [scrollElement]
  );

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const { scrollElement, setScrollElement } = useContext(ScrollContext);

  return { scrollElement, setScrollElement };
};
